import React, { useCallback, useState } from "react"
import ModalDialog, { ModalHeader } from "@atlaskit/modal-dialog"
import { DynamicTableStateless } from "@atlaskit/dynamic-table"
import { getAnalysisType, getDueBadge } from "./card-types"
import TextField from "../shared/components/textfield"

const columns = (allSelected = false, onSelectAll = () => {}) => {
    return {
        cells: [
            {
                key: "barcode",
                content: "Barcode",
            },
            {
                key: "analysis",
                content: "Analysis",
            },
            {
                key: "dueDate",
                content: "Due Date",
            },
            {
                key: "select",
                content: (
                    <input
                        type="checkbox"
                        checked={allSelected}
                        onChange={(e) => onSelectAll(e.target.checked)}
                    />
                ),
            },
        ],
    }
}

function Ship({ items, selectedItems, onSelect }) {
    const allSelected = items.every((item) => selectedItems[item.barcode])
    const rows = items.map((item, index) => ({
        key: `row-${index}-${item.barcode}`,
        cells: [
            {
                content: <span>{item.barcode}</span>,
            },
            {
                content: <span>{getAnalysisType(item)}</span>,
            },
            {
                content: <span>{getDueBadge(item.dueDate)}</span>,
            },
            {
                content: (
                    <span>
                        <input
                            type="checkbox"
                            checked={!!selectedItems[item.barcode]}
                            onChange={(e) =>
                                onSelect({
                                    ...selectedItems,
                                    [item.barcode]: e.target.checked,
                                })
                            }
                        />
                    </span>
                ),
            },
        ],
    }))

    return (
        <div style={{ height: "65vh", overflow: "auto" }}>
            <DynamicTableStateless
                head={columns(allSelected, () =>
                    onSelect(
                        allSelected
                            ? {}
                            : items.reduce((acc, item) => {
                                  return {
                                      ...acc,
                                      [item.barcode]: true,
                                  }
                              }, {}),
                    ),
                )}
                rows={rows}
            />
        </div>
    )
}

const selectionModalByType = {
    Registered: {
        title: ({ supplier }) => `Select barcodes to ship to ${supplier.name}`,
        component: Ship,
        action: {
            name: "Ship",
            handler: (api, selectedItems, { supplier }) =>
                api.ship(selectedItems, supplier.id),
        },
    },
}

export default function SelectionModal({ selection, onClose, api, onAction }) {
    const [selectedItems, setSelectedItems] = useState({})
    const [filter, setFilter] = useState("")
    const reallySelected = Object.keys(selectedItems).filter(
        (key) => selectedItems[key],
    )
    const items = (selection?.items ?? []).filter((item) =>
        item.barcode.toLowerCase().includes(filter.toLowerCase()),
    )

    const checkOnEnter = useCallback(
        (e) => {
            if (e.key === "Enter" && items.length === 1) {
                setSelectedItems((prev) => ({
                    ...prev,
                    [items[0].barcode]: true,
                }))
            }
        },
        [items],
    )

    if (selection) {
        const modal = selectionModalByType[selection.status]
        const { title, action } = modal
        return (
            <ModalDialog
                actions={[
                    {
                        text: "Close",
                        onClick: onClose,
                        appearance: "secondary",
                    },
                    {
                        text: action.name,
                        onClick: () =>
                            action
                                .handler(api, reallySelected, selection)
                                .then(onAction),
                        appearance: "primary",
                        isDisabled: reallySelected.length === 0,
                    },
                ]}
                width="800px"
                height="100%"
                shouldCloseOnOverlayClick={false}
                scrollBehavior="outside">
                <ModalHeader>
                    <h3>{title(selection)}</h3>
                </ModalHeader>
                <div className="supervisor-selection-modal">
                    <TextField
                        placeholder="Filter..."
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        onKeyDown={(e) => checkOnEnter(e)}
                    />
                    <modal.component
                        {...selection}
                        items={items}
                        selectedItems={selectedItems}
                        onSelect={setSelectedItems}
                    />
                </div>
            </ModalDialog>
        )
    }
}
