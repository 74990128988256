import React from "react"
import Button from "@atlaskit/button"
import { getSelectedItems } from "./store"
import KanbanCard from "./kanban-card"

export default function Lane({
    api,
    dispatch,
    lane,
    items = [],
    total = 0,
    additionalData = {},
    selectedItems = {},
    onSelectItem = () => {},
    onSelectAll = () => {},
    onSelectFromList = () => {},
    onLaneAction = () => {},
    onLoadMore = () => {},
    onOpenPage = () => {},
}) {
    return (
        <div className="lane">
            <div className="lane-header">
                <div className="lane-title">{lane.title}</div>
                <div className="lane-stats">
                    {items.length}/{total}
                </div>
                <div className="lane-header-actions">
                    {lane.headerActions
                        ? lane.headerActions.map((action) => (
                              <action.component
                                  key={action.name}
                                  api={api}
                                  dispatch={dispatch}
                                  lane={lane}
                                  onSelectAll={onSelectAll}
                                  onSelectFromList={onSelectFromList}
                                  selected={getSelectedItems(selectedItems)}
                                  onAction={onLaneAction}
                                  data={additionalData}
                                  isEnabled={action.isEnabled}
                                  hidden={action.hidden ?? false}
                              />
                          ))
                        : null}
                </div>
            </div>
            <div className="lane-actions">
                {lane.actions
                    ? lane.actions.map((action) => (
                          <action.component
                              key={action.name}
                              api={api}
                              dispatch={dispatch}
                              lane={lane}
                              selected={getSelectedItems(selectedItems)}
                              onAction={onLaneAction}
                              data={additionalData}
                          />
                      ))
                    : null}
            </div>
            <div className="lane-body">
                {items.map((item, i) => (
                    <KanbanCard
                        key={i}
                        item={item}
                        type={lane.status}
                        selected={selectedItems[item.barcode] ?? false}
                        onSelect={onSelectItem}
                        onOpen={onOpenPage}
                    />
                ))}
            </div>
            <div className="lane-footer">
                {items.length < total ? (
                    <Button onClick={onLoadMore}>Load more</Button>
                ) : null}
            </div>
        </div>
    )
}
