import { allDrugs } from "../patients/patient-drugs"
import {
    allDiseasesGroups,
    diseasesForGroup,
} from "../patients/patient-diseases"
import CheckIcon from "@atlaskit/icon/glyph/check"
import WarningIcon from "@atlaskit/icon/glyph/warning"
import React from "react"
import { error } from "../../shared/notifications"

export function geneticAnalysesMap(geneticAnalyses) {
    return geneticAnalyses.reduce((acc, ga) => {
        return { ...acc, [ga.barcode]: ga }
    }, {})
}

export function cleanList(patient, property) {
    return (patient.properties[property] || "").split("|")
}

export function getBarcodes(patient) {
    return cleanList(patient, "Barcode")
}

export function skipError(error) {
    return error.toLowerCase().indexOf("value is in a bottom state") === 0
        ? ""
        : error
}

export function matching(condition, typeElement = "text") {
    return typeElement === "input"
        ? {
              border: "1px solid " + (condition ? "green" : "red"),
              margin: "8px",
          }
        : {
              color: condition ? "green" : "red",
          }
}

export function drawMatchingIcon(condition) {
    return condition ? (
        <CheckIcon size="small" primaryColor="green" />
    ) : (
        <WarningIcon size="small" primaryColor="red" />
    )
}

export function existNameSurname(props) {
    return existsProp(props, "Nome") && existsProp(props, "Cognome")
}

export function existsEmail(props) {
    return existsProp(props, "Email")
}

export function existsProp(properties, key) {
    return !!properties[key]
}

export function notExistsProp(properties, key) {
    return !existsProp(properties, key)
}

function trimIfString(prop) {
    if (typeof prop === "string") {
        return prop.trim()
    }
    return prop
}

export function trimProperties(properties) {
    return Object.keys(properties).reduce((result, key) => {
        result[key] = trimIfString(properties[key])
        return result
    }, {})
}

export function mergeBarcodes(barcodes, updated, removed) {
    return Object.keys(updated)
        .reduce((res, index) => {
            return res.map((b, i) => (i !== Number(index) ? b : updated[index]))
        }, barcodes)
        .filter((_, index) => removed.indexOf(index) === -1)
}

export function cleanTemplate(template) {
    return template.trim().toUpperCase()
}

export function getSex(sex) {
    switch (sex.toLowerCase()) {
        case "male":
        case "maschio":
            return "male"
        case "female":
        case "femmina":
            return "female"
        default:
            return "unspecified"
    }
}

export function getBornOn(bornOn) {
    if (!bornOn) return null
    return bornOn.replace("|", "").trim()
}

export function getMenopause(menoPause, sex, femaleSymptoms) {
    if (menoPause?.toLowerCase() === "si") return "post"
    if (menoPause) return "pre"

    const lowerSex = sex.toLowerCase()
    if (lowerSex === "femmina" || lowerSex === "female") {
        if (
            femaleSymptoms.toLowerCase().indexOf("premenopausa") !== -1 ||
            femaleSymptoms.toLowerCase().indexOf("pre-menopause") !== -1
        )
            return "pre"
        else if (
            femaleSymptoms.toLowerCase().indexOf("menopausa") !== -1 ||
            femaleSymptoms.toLowerCase().indexOf("menopause") !== -1
        )
            return "post"
        return "pre"
    }
    return "notapplicable"
}

export function getEthnicity(ethnicity) {
    switch (ethnicity.toLowerCase()) {
        case "caucasian":
        case "caucasica":
            return "caucasian"
        case "african":
        case "africana":
            return "african"
        case "amerindian":
        case "amerinda":
            return "amerindian"
        case "asian":
        case "asiatica":
            return "asian"
        default:
            return "unspecified"
    }
}

export function getInteger(value) {
    if (!value) return null
    const result = Math.round(Number(value.replace(",", ".")))
    if (isNaN(result) || !isFinite(result)) return null
    return result
}

export function getHeight(height) {
    const result = getInteger(height)
    if (result < 3) {
        return Math.round(result * 100.0)
    }
    return Math.round(result)
}

export function getOtherDrugs(properties) {
    return (
        properties["AltriFarmaciInUso"] ? [properties["AltriFarmaciInUso"]] : []
    )
        .concat(
            properties["AltriFarmaciNonTollerati"]
                ? [properties["AltriFarmaciNonTollerati"]]
                : [],
        )
        .join(", ")
}

export const drugsMatches = {
    it: {
        birthControlPill: ["pillola anticoncezionale"],
        protonPumpInhibitors: ["Inibitori di pompa protonica"],
        antidepressant: ["Antidepressivi"],
        antihypertensive: ["Antipertensivi"],
        statins: ["Statine"],
        hormoneReplacementTherapy: ["Terapia ormonale sostitutiva"],
        antibiotics: ["Antibiotici"],
        anticoagulants: ["Anticoagulanti"],
        hypoglycemics: ["Ipoglicemizzanti"],
        chemotherapeutics: ["Chemioterapici"],
        biologicalMedicines: ["Farmaci Biologici"],
        intestinalAntiInflammatories: ["Antinfiammatori intestinali"],
    },
    eng: {
        birthControlPill: ["Birth control"],
        protonPumpInhibitors: ["Proton pump inhibitors"],
        antidepressant: ["Antidepressants"],
        antihypertensive: ["Antihypertensive"],
        statins: ["Statins"],
    },
}

export const diseasesGroupMatches = {
    respiratory: "SintomiRespiratori",
    hematologic: "SintomiEmatologici",
    bonesAndJoints: "SintomiOssa",
    cardiovascular: "SintomiCardioVascolari",
    food: "SintomiAlimentari",
    kidneyUrogenital: "SintomiUroGenitali",
    endocrine: "SintomiEndocrini",
    dermatological: "SintomiDermatologici",
    neurologic: "SintomiNeurologici",
    female: "SintomiFemminili",
    oral: "SintomiOrali",
}

export const diseasesMatches = {
    it: {
        allergy: ["Allergia"],
        asthma: ["Asma"],

        anemia: ["Anemia"],

        rheumatoidArthritis: ["Artrite reumatoide"],
        arthrosis: ["Artrosi"],
        osteoporosis: ["Osteoporosi"],
        fibromyalgia: ["Fibromialgia"],

        atherosclerosis: ["Arteriosclerosi", "Aterosclerosi"],
        thrombosis: ["Trombosi"],
        arterialHypotension: ["Ipotensione arteriosa", "pressione bassa"],
        hypertension: ["Ipertensione"],
        highCholesterol: ["Colesterolo alto"],
        heartFailure: ["Insufficienza cardiaca", "cardiopatia"],
        highHomocysteine: ["Omocisteina alta"],
        abdominalSwellingPain: [
            "Digestione lenta o gonfiore addominale",
            "Gonfiore/dolore addominale",
        ],
        liverDysfunction: ["Disfunzioni epatiche"],
        constipation: ["Stipsi", "Stitichezza"],
        lactoseIntolerance: ["Intolleranza al lattosio"],
        celiacDisease: ["Celiachia"],
        diverticulosis: ["Diverticolosi"],
        gastritis: ["Gastrite"],
        iBD: ["Morbo di Crohn o Colite Ulcerativa", "IBD"],
        dCA: ["DCA"],
        diarrhea: ["Diarrea"],

        kidneyStones: ["Calcoli renali"],
        kidneyFailure: ["insufficienza renale"],
        recurrentCystitis: ["Cistiti ricorrenti", "Cistite"],
        candidiasis: ["Candidosi"],
        urethritisProstatitis: ["Uretriti o prostatiti", "Prostatite"],

        typeIDiabetes: ["Diabete tipo 1"],
        typeIIDiabetes: ["Diabete tipo 2"],
        hyperthyroidism: ["Ipertiroidismo"],
        hypothyroidism: ["Ipotiroidismo"],

        urticaria: ["Orticaria"],
        psoriasis: ["Psoriasi"],
        dermatitis: ["Dermatite"],
        acne: ["Acne"],

        hyperglycemia: ["Iperglicemia"],
        insulinResistance: ["Insulino resistenza", "Insulino-resistenza"],

        depression: ["Depressione"],
        panicAttacks: ["Attacchi di panico"],
        migraine: ["Emicrania"],

        cankerSores: ["Afte"],
        periodontitis: ["Paradontite"],

        polycysticOvarySyndrome: [
            "Sindrome da ovaio policistico (PCOS)",
            "PCOS",
        ],
        endometriosis: ["Endometriosi"],
        infertility: ["Infertilità"],
        preMestrualSyndrome: ["Sindrome premestruale"],
        adenomyosis: ["Adenomiosi"],
        severeMenstrualPain: ["Forti dolori mestruali"],
    },
    eng: {
        allergy: ["Allergies (specify in the notes what kind of allergy)"],
        asthma: ["Asma"],

        anemia: ["Anemia"],

        rheumatoidArthritis: ["Rheumatoid arthritis"],
        arthrosis: ["Arthrosis"],
        osteoporosis: ["Osteoporosis"],
        fibromyalgia: ["Fibromyalgia"],

        atherosclerosis: ["Atherosclerosis"],
        thrombosis: ["Thrombosis"],
        arterialHypotension: ["Arterial hypotension (low blood pressure)"],
        hypertension: ["Arterial hypertension (high blood pressure)"],
        highCholesterol: ["High cholesterol"],
        heartFailure: ["Hearth failure"],
        highHomocysteine: ["High omocystein"],

        abdominalSwellingPain: ["Slow digestion or bloating"],
        liverDysfunction: ["Liver dysfunction"],
        constipation: ["Constipation"],
        lactoseIntolerance: ["Lactose intollerance"],
        celiacDisease: ["Celiac disease"],
        diverticulosis: ["Diverticulosis"],
        gastritis: ["Gastritis or gastroesophageal reflux"],
        iBD: ["Crohn’s disease or ulcerative colitis"],
        dCA: ["DCA"],
        diarrhea: ["Diarrhea"],

        kidneyStones: ["Kidney stones"],
        kidneyFailure: ["Kidney failure"],
        recurrentCystitis: ["Recurrent cystitis"],
        candidiasis: ["Candidiasis"],
        urethritisProstatitis: ["Urethritis or prostatitis"],

        typeIDiabetes: ["Type 1 diabetes"],
        typeIIDiabetes: ["Type 2 diabetes"],
        hyperthyroidism: ["Hyperthyroidism"],
        hypothyroidism: ["Hypothyroidism"],

        urticaria: ["Orticaria"],
        psoriasis: ["Psoriasi"],
        dermatitis: ["Dermatite"],
        acne: ["Acne"],

        hyperglycemia: ["Hyperglycemia"],
        insulinResistance: ["Insulin resistance"],

        depression: ["Depression"],
        panicAttacks: ["Panic attaks"],
        migraine: ["Migraine"],

        cankerSores: ["Afte"],
        periodontitis: ["Paradontite"],

        polycysticOvarySyndrome: ["PCOS"],
        endometriosis: ["Endometriosi"],
        infertility: ["Infertilità"],
        preMestrualSyndrome: ["Sindrome premestruale"],
        adenomyosis: ["Adenomiosi"],
        severeMenstrualPain: ["Forti dolori mestruali"],
    },
}

export function getDrug(drug, language, val) {
    const names = drugsMatches[language][drug]
    const value = val?.toLowerCase() ?? ""
    return (
        (names &&
            names.some((name) => value.indexOf(name.toLowerCase()) !== -1)) ||
        false
    )
}

export function getDrugs(properties, language) {
    return allDrugs().map((name) => ({
        name,
        data: {
            inUse: getDrug(name, language, properties["FarmaciInUso"]),
            notTolerated: getDrug(
                name,
                language,
                properties["FarmaciNonTollerati"],
            ),
        },
    }))
}

export function getSmokerType(smoker) {
    switch (smoker.toLowerCase()) {
        case "yes":
        case "si":
            return "yes"
        case "no":
            return "no"
        default:
            return "noMore"
    }
}

export function getExerciseType(exercise) {
    return (
        {
            "No, sedentario": "low",
            "Si, ogni tanto (1 volta a settimana o meno)": "low",
            "Si, con costanza (2 volte a settimana)": "average",
            "Si, con costanza (3-4 volte a settimana)": "average",
            "Si, con costanza (4-6 volte a settimana)": "average",
            "Si, pratico sport a livello amatoriale (5-7 volte a settimana)":
                "high",
            "Si, pratico sport a livello agonistico (5-7 volte a settimana)":
                "high",
            No: "low",
            "Yes, sometimes (once a week)": "low",
            "Yes, regularly (2-3 times a week)": "average",
            "Yes, regularly (3-4 times a week)": "average",
            "Yes, regularly (4-6 times a week)": "average",
            "Yes, I am an athlete (5-7 times a week)": "high",
        }[exercise] || "unspecified"
    )
}

export function getBodyImpedenceAssesment(bia = "") {
    if (!bia.trim()) return ""
    const [rz, xc] = bia
        .trim()
        .split(",")
        .map((v) => v.trim())
    if (isNaN(Number(rz)) || isNaN(Number(xc))) return ""
    return `${rz || ""},${xc || ""}`
}

export function getDrunkWater(drunkWater = "") {
    switch (drunkWater.toLowerCase()) {
        case "<1":
            return "low"
        case "1-2":
            return "average"
        case ">2":
            return "high"
        default:
            return "unspecified"
    }
}

export function getPittsburghPoints(points = "") {
    switch (points.toLowerCase()) {
        case "<5":
            return "low"
        case ">5":
            return "high"
        default:
            return "unspecified"
    }
}

export function getIpaqPoints(points = "") {
    switch (points.toLowerCase()) {
        case "<700 met":
            return "low"
        case "tra 700 e 2519 met":
            return "average"
        case ">2520 met":
            return "high"
        default:
            return "unspecified"
    }
}

export function getDiseases(properties, language) {
    return allDiseasesGroups().map((group) => ({
        name: group,
        items: diseasesForGroup(group).map((disease) => ({
            name: disease,
            data: {
                symtoms: getDisease(
                    disease,
                    language,
                    properties[diseasesGroupMatches[group]],
                ),
                familiarity: false,
            },
        })),
    }))
}

export function getDisease(disease, language, val) {
    const names = diseasesMatches[language][disease]
    const value = val?.toLowerCase() ?? ""
    return (
        (names &&
            names.some((name) => value.indexOf(name.toLowerCase()) !== -1)) ||
        false
    )
}

export function hasGuardian(prop) {
    return prop === "Si" || prop === "Yes" ? "yes" : "no"
}

export const showError = (e) => {
    let ex = e
    if (typeof e === "string") {
        try {
            ex = JSON.parse(e)
        } catch (e) {
            ex = {
                Message: "Error during approval",
                StackTrace: "",
            }
        }
    }
    let msg
    if (ex && ex.Message) {
        msg = skipError(ex.Message)
        console.error(ex.StackTrace)
    } else if (ex && ex.length) {
        msg = ex
            .map((iex) => {
                if (iex && iex.Message) {
                    return skipError(iex.Message)
                }
                return ""
            })
            .filter((m) => m)
            .join(", ")
    }
    if (msg) {
        error(msg, {
            dismiss: false,
        })
    }
}
