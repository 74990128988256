import React from "react"
import "./kanban.css"
import { mkApi } from "./api"
import { useStateContext } from "../../shared/state-context"
import { actions } from "./store"
import Lane from "./lane"
import { defaultLanes } from "./lane-types"

function Kanban({
    id = "default",
    lanes = defaultLanes,
    reporters = [],
    suppliers = [],
    getAnalyses = () => {},
    filter = {},
    loadAll,
    openModal = () => {},
}) {
    const { dispatch } = useStateContext()
    const api = mkApi(dispatch)

    const reportersOptions = reporters.map((r) => ({
        label: r.name,
        value: r.email,
    }))

    const suppliersOptions = suppliers.map((r) => ({
        label: r.name,
        value: r.id,
    }))
    const additionalData = {
        reporters: reportersOptions,
        suppliers: suppliersOptions,
        reportersWithNone: [
            { label: "Unassigned", value: "unassigned" },
            ...reportersOptions,
        ],
    }

    return (
        <div className={`kanban-container kanban-container-${id}`}>
            {lanes.map((lane) => {
                const analyses = getAnalyses(lane.status)
                const items = analyses?.items ?? []
                const allSelected = items.every(
                    (item) => analyses?.selected[item.barcode],
                )
                return (
                    <Lane
                        key={lane.status}
                        api={api}
                        dispatch={dispatch}
                        lane={lane}
                        items={items}
                        total={analyses?.pagination?.totalItems ?? 0}
                        additionalData={{
                            ...additionalData,
                            ...getAnalyses(lane.status).additionalData,
                        }}
                        selectedItems={analyses?.selected}
                        onSelectItem={(item, on) =>
                            dispatch(
                                actions.selectItems(lane.status, [item], on),
                            )
                        }
                        onSelectAll={() =>
                            dispatch(
                                actions.selectItems(
                                    lane.status,
                                    items.map((item) => item.barcode),
                                    !allSelected,
                                ),
                            )
                        }
                        onSelectFromList={() =>
                            api.loadAllByStatus({
                                status: lane.status,
                                ...filter,
                            })
                        }
                        onLaneAction={loadAll}
                        onLoadMore={() =>
                            api.loadByStatus(
                                {
                                    status: lane.status,
                                    pageNumber:
                                        analyses?.pagination?.pageNumber + 1,
                                    pageSize: 10,
                                    sortBy: "supervisor",
                                    ...filter,
                                },
                                true,
                            )
                        }
                        onOpenPage={(url) => openModal(`${url}?modal=true`)}
                    />
                )
            })}
        </div>
    )
}

export default Kanban
