import React from "react"
import { today, toDays, toIta } from "../../shared/dates"
import { getDoneStatus, getDueStatus } from "./card-styles"
import { analysisTypes } from "../../analysisTypes"

export function getAnalysisType(item) {
    const analysisType = analysisTypes.find(
        (x) => x.value === item.analysisType,
    )
    const subType = item.analysisSubType
    if (analysisType) {
        if (analysisType.value === "MacroBiota" && subType === "AllGut") {
            return (
                <div style={{ display: "flex", alignContent: "center" }}>
                    <span>All GUT</span>
                    {item.hasLabResults && (
                        <span className="icon-loaded icon-loaded-gut">B</span>
                    )}
                    {item.hasExtraLabResults && (
                        <span className="icon-loaded icon-loaded-allgut">
                            O
                        </span>
                    )}
                </div>
            )
        }
        if (
            analysisType.value === "Epigene" &&
            subType &&
            subType !== "Unknown"
        ) {
            return analysisType.label + " " + subType
        }
        return analysisType.label
    }
    return ""
}

function getDueDays(dueDate) {
    if (!dueDate) {
        return ""
    }

    const due = toIta(dueDate)
    const diff = toDays(today, due)
    if (diff < 0) return "Overdue"
    if (diff === 0) return "Today"
    if (diff === 1) return "1 day"
    return `${diff} days`
}

function getDateTooltip(date) {
    if (date) return toIta(date)
    return ""
}

function getDoneDate(doneDate) {
    if (!doneDate) {
        return ""
    }

    return toIta(doneDate)
}

export function getDueBadge(dueDate) {
    return (
        <span
            data-tooltip={getDateTooltip(dueDate)}
            className={`barcode-duestatus barcode-duestatus-${getDueStatus(
                dueDate,
            )}`}>
            {getDueDays(dueDate)}
        </span>
    )
}

function getDoneBadge(doneDate, dueDate) {
    return (
        <span
            className={`barcode-duestatus barcode-duestatus-${getDoneStatus(
                doneDate,
                dueDate,
            )}`}>
            {getDoneDate(doneDate)}
        </span>
    )
}

function AnalysisType({ item }) {
    return (
        <div className="card-data-item card-data-analysis-type">
            {getAnalysisType(item)}
        </div>
    )
}

function Patient({ item }) {
    return (
        <div className="card-data-item card-data-patient">
            {item.patientAnonymous
                ? "Anonymous"
                : `${item.patientFirstName} ${item.patientLastName}`}
        </div>
    )
}

function Reporter({ item }) {
    return (
        <div className="card-data-item card-data-reporter">
            <span className="reporter-label">Reporter: </span>
            {item.reporterName}
        </div>
    )
}

function Supplier({ item }) {
    return (
        <div className="card-data-item card-data-supplier">
            <span className="reporter-label">Supplier: </span>
            {item.supplierName}
        </div>
    )
}

function EmptyRow() {
    return <div className="card-data-item"></div>
}

function DueDate({ item }) {
    return (
        <div className="due-date-container">
            <span className="due-label">Due: </span>
            {getDueBadge(item.dueDate)}
        </div>
    )
}

function DoneDate({ item }) {
    return (
        <div className="done-date-container">
            <span className="done-label">Done: </span>
            {getDoneBadge(item.doneDate, item.dueDate)}
        </div>
    )
}

export const cardByType = {
    NotYetRegistered: (item) => <AnalysisType item={item} />,
    Registered: (item) => (
        <>
            <AnalysisType item={item} />
            <Patient item={item} />
            <EmptyRow />
            <DueDate item={item} />
        </>
    ),
    Shipped: (item) => (
        <>
            <AnalysisType item={item} />
            <Patient item={item} />
            <Supplier item={item} />
            <DueDate item={item} />
        </>
    ),
    Analysed: (item) => (
        <>
            <AnalysisType item={item} />
            <Patient item={item} />
            <Supplier item={item} />
            <DueDate item={item} />
        </>
    ),
    Assigned: (item) => (
        <>
            <AnalysisType item={item} />
            <Patient item={item} />
            <Reporter item={item} />
            <DueDate item={item} />
        </>
    ),
    Done: (item) => (
        <>
            <AnalysisType item={item} />
            <Patient item={item} />
            <Reporter item={item} />
            <DoneDate item={item} />
        </>
    ),
}
