import { match } from "../../shared/store"

export const statuses = [
    "NotYetRegistered",
    "Registered",
    "Shipped",
    "Analysed",
    "Assigned",
    "Done",
]

export function getDataToSelect(state) {
    if (state._analysesToSelect?.status) {
        return {
            ...state._analysesToSelect,
            ...(state._additionalData[state._analysesToSelect.status] ?? {}),
        }
    }
    return state._analysesToSelect
}

export function getAdditionalData(state, status) {
    return state._additionalData[status] || {}
}

export function getDataByStatus(state, status) {
    return {
        error: state._error,
        items: state._analyses[status]?.items || [],
        pagination: state._analyses[status]?.pagination || {},
        selected: state._analyses[status]?.selected || {},
        additionalData: state._additionalData[status] || {},
    }
}

export function getSelectedItems(selectedObject) {
    return Object.entries(selectedObject)
        .filter((e) => e[1])
        .map((e) => e[0])
}

export function getFilter(state) {
    return {
        ...state._filter,
    }
}

export const initialState = {
    _analyses: {},
    _error: null,
    _filter: {
        type: "",
        referral: "",
        pattern: "",
        reporter: "",
        supplier: "",
    },
    _analysesToSelect: null,
    _additionalData: {},
}

export const reducer = (state, action) => {
    return match(action)
        .case("FETCH-BYSTATUS-SUCCESS", handleFetchByStatusSuccess)
        .case("FETCH-ALL-BYSTATUS-SUCCESS", handleFetchAllByStatusSuccess)
        .case("FETCH-ERROR", handleFetchError)
        .case("SELECT-ITEMS", handleSelectItems)
        .case("SET-STATUS-DATA", handleSetStatusData)
        .apply(state)
}

export const actions = {
    loadByStatus(data) {
        return { type: "FETCH-BYSTATUS-SUCCESS", data }
    },
    loadAllByStatus(data) {
        return { type: "FETCH-ALL-BYSTATUS-SUCCESS", data }
    },
    error(error) {
        return { type: "FETCH-ERROR", error }
    },
    selectItems(lane, items, status) {
        return { type: "SELECT-ITEMS", data: { lane, items, status } }
    },
    setStatusData(status, data) {
        return { type: "SET-STATUS-DATA", data: { status, data } }
    },
}

const handleFetchAllByStatusSuccess = (state, action) => {
    const { status, items } = action.data

    if (status) {
        return {
            ...state,
            _analysesToSelect: {
                status,
                items,
            },
        }
    }
    return {
        ...state,
        _analysesToSelect: null,
    }
}

const handleSetStatusData = (state, action) => {
    const { status, data } = action.data

    return {
        ...state,
        _additionalData: {
            ...state._additionalData,
            [status]: {
                ...state._additionalData[status],
                ...data,
            },
        },
    }
}

const handleFetchByStatusSuccess = (state, action) => {
    const {
        status,
        items,
        pagination,
        append = false,
        reporter,
        referral,
        supplier,
        type,
        pattern,
    } = action.data

    if (append) {
        return {
            ...state,
            _analyses: {
                ...state._analyses,
                [status]: {
                    items: [...state._analyses[status].items, ...items],
                    pagination,
                    selected: state._analyses[status].selected,
                },
            },
            _filter: {
                reporter: reporter || "",
                referral: referral || "",
                supplier: supplier || "",
                type: type || "",
                pattern: pattern || "",
            },
        }
    }
    return {
        ...state,
        _analyses: {
            ...state._analyses,
            [status]: {
                items,
                pagination,
                selected: {},
            },
        },
        _filter: {
            reporter: reporter || "",
            referral: referral || "",
            supplier: supplier || "",
            type: type || "",
            pattern: pattern || "",
        },
    }
}

const handleSelectItems = (state, action) => {
    const { lane, items, status } = action.data
    const newSelected = items.reduce(
        (acc, item) => {
            return {
                ...acc,
                [item]: status,
            }
        },
        { ...state._analyses[lane].selected },
    )
    return {
        ...state,
        _analyses: {
            ...state._analyses,
            [lane]: {
                ...state._analyses[lane],
                selected: newSelected,
            },
        },
    }
}

const handleFetchError = (state, action) => {
    const { error } = action
    return {
        ...state,
        _analyses: {},
        _analysesToSelect: null,
        _error: error,
    }
}
