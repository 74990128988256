import React from "react"

import SelectOptionAction from "./select-option-action"
import { actions } from "./store"

function ship(api, selectedItems, supplier) {
    return api.ship(selectedItems, supplier)
}

function assign(api, selectedItems, reporter) {
    return api.assignReporter(selectedItems, reporter)
}

function reassign(api, selectedItems, reporter) {
    if (reporter === "unassigned") {
        return api.unassignReporter(selectedItems)
    } else {
        return api.assignReporter(selectedItems, reporter)
    }
}

function setSupplier(dispatch, status, supplier) {
    dispatch(actions.setStatusData(status, { supplier }))
}

function Ship({ data, lane, selected, onAction, api, dispatch }) {
    return (
        <SelectOptionAction
            action={{
                name: "Ship",
                options: data.suppliers,
            }}
            onAction={(supplier) =>
                ship(api, selected, supplier).then(onAction)
            }
            onSelectionChange={(supplier) =>
                setSupplier(dispatch, lane.status, {
                    id: supplier,
                    name: data.suppliers.find((s) => s.value === supplier)
                        .label,
                })
            }
            isEnabled={selected.length > 0}
        />
    )
}

function Assign({ data, selected, onAction, api }) {
    return (
        <SelectOptionAction
            action={{
                name: "Assign",
                options: data.reporters,
            }}
            onAction={(reporter) =>
                assign(api, selected, reporter).then(onAction)
            }
            isEnabled={selected.length > 0}
        />
    )
}

function Reassign({ data, selected, onAction, api }) {
    return (
        <SelectOptionAction
            action={{
                name: "Reassign",
                options: data.reportersWithNone,
            }}
            onAction={(reporter) =>
                reassign(api, selected, reporter).then(onAction)
            }
            isEnabled={selected.length > 0}
        />
    )
}

function SelectAll({ onSelectAll = {}, hidden }) {
    if (hidden) {
        return null
    }
    return (
        <a href="#" onClick={onSelectAll}>
            Select All
        </a>
    )
}

function SelectFromList({
    onSelectFromList = {},
    data,
    isEnabled = () => true,
    hidden = false,
}) {
    if (hidden) {
        return null
    }
    const enabled = isEnabled(data)
    return enabled ? (
        <a href="#" onClick={onSelectFromList}>
            Select
        </a>
    ) : (
        <span>Select</span>
    )
}

export const defaultLanes = [
    {
        status: "NotYetRegistered",
        title: "To Register",
    },
    {
        status: "Registered",
        title: "Registered",
        headerActions: [
            {
                name: "selectFromList",
                component: SelectFromList,
                isEnabled: (data) => !!data.supplier,
                hidden: false,
            },
            {
                name: "selectAll",
                component: SelectAll,
                hidden: true,
            },
        ],
        actions: [
            {
                name: "ship",
                component: Ship,
            },
        ],
    },
    {
        status: "Shipped",
        title: "Shipped",
    },
    {
        status: "Analysed",
        title: "Analysed",
        headerActions: [
            {
                name: "selectAll",
                component: SelectAll,
            },
        ],
        actions: [
            {
                name: "assign",
                component: Assign,
            },
        ],
    },
    {
        status: "Assigned",
        title: "Assigned",
        headerActions: [
            {
                name: "selectAll",
                component: SelectAll,
            },
        ],
        actions: [
            {
                name: "reassign",
                component: Reassign,
            },
        ],
    },
    {
        status: "Done",
        title: "Done",
    },
]
