import React from "react"

import { Link } from "react-router-dom"
import { routes } from "../../shared/routing"
import Card from "./card"
import { cardStyles } from "./card-styles"
import { cardByType } from "./card-types"
import { cardActions } from "./card-actions"

function getCardTitle(item) {
    return (
        <>
            <Link
                to={{
                    pathname: routes.geneticAnalyses.edit.buildUrl(
                        item.barcode,
                    ),
                }}
                target="_blank">
                {item.barcode}
            </Link>
            {item.referral ? (
                <span>
                    {" "}
                    -{" "}
                    <Link
                        to={{
                            pathname:
                                routes.referrals.index.routes.edit.buildUrl(
                                    item.referral,
                                ),
                        }}
                        target="_blank">
                        {item.referral}
                    </Link>
                </span>
            ) : null}
        </>
    )
}

export default function KanbanCard({ item, type, selected, onSelect, onOpen }) {
    return (
        <Card
            style={cardStyles[type](item)}
            type={type}
            language={item?.language === "eng" ? `(eng)` : ""}
            actions={cardActions[type]({ item, selected, onSelect, onOpen })}
            body={cardByType[type](item)}
            title={getCardTitle(item)}
        />
    )
}
