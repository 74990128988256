import React, { useEffect, useState } from "react"
import SectionHeader from "../shared/components/section-header.jsx"
import Filter from "./filter.jsx"
import { mkApi } from "./api.js"
import {
    useStateContext,
    withStateContext,
} from "../../shared/state-context.jsx"
import {
    actions,
    getDataByStatus,
    getDataToSelect,
    getFilter,
    initialState,
    reducer,
    statuses,
} from "./store.js"
import Kanban from "./kanban.jsx"
import Button from "@atlaskit/button"
import ModalDialog from "@atlaskit/modal-dialog"
import { routes } from "../../shared/routing"
import SelectionModal from "./selection-modal.jsx"
import { uniqueId } from "lodash"

function SupervisorDashboard() {
    const { state, dispatch } = useStateContext()
    const { referral, reporter, supplier, pattern, type } = getFilter(state)
    const api = mkApi(dispatch)

    const [reporters, setReporters] = useState([])
    const [suppliers, setSuppliers] = useState([])

    const [modalPage, setModalPage] = useState(null)

    const closeModalPage = () => {
        setModalPage(null)
        loadAll()
    }

    const closeSelectionPage = () => {
        closeSelection()
        loadAll()
    }

    function loadAll({
        referralFilter,
        reporterFilter,
        supplierFilter,
        searchFilter,
        typeFilter,
    } = {}) {
        Promise.all(
            statuses.map((status) =>
                api.loadByStatus({
                    status,
                    type: typeFilter ?? type,
                    pattern: searchFilter ?? pattern,
                    reporter: reporterFilter ?? reporter,
                    referral: referralFilter ?? referral,
                    supplier: supplierFilter ?? supplier,
                    pageNumber: 0,
                    pageSize: 10,
                    sortBy: "supervisor",
                }),
            ),
        )
    }

    useEffect(() => {
        api.loadReporters().then((r) => {
            setReporters(r.items)
        })
        api.loadAllSuppliers().then((r) => {
            setSuppliers(r.items)
        })
        loadAll()
    }, [])

    function onFilter(filterType, value) {
        switch (filterType) {
            case "type":
                loadAll({
                    typeFilter: value,
                })
                break
            case "referral":
                loadAll({
                    referralFilter: value,
                })
                break
            case "reporter":
                loadAll({ reporterFilter: value })
                break
            case "supplier":
                loadAll({
                    supplierFilter: value,
                })
                break
            default:
                break
        }
    }

    const currentFilter = {
        type,
        referral,
        reporter,
        pattern,
        supplier,
    }

    const selection = getDataToSelect(state)

    function closeSelection() {
        dispatch(actions.loadAllByStatus({}))
    }

    return (
        <div id="dashboard-supervisor">
            <SectionHeader title="Supervisor Dashboard" />
            <div className="mt-4 row">
                <div className="col-10">
                    <Filter
                        type={type}
                        referral={referral}
                        reporter={reporter}
                        supplier={supplier}
                        onFilter={onFilter}
                        reporters={reporters ?? []}
                        suppliers={suppliers ?? []}
                        onChangeSearch={(e) =>
                            loadAll({ searchFilter: e.target.value })
                        }
                        loadReferrals={(pattern) => api.loadReferrals(pattern)}
                    />
                </div>
                <div className="col-2">
                    <Button
                        appearance="primary"
                        onClick={(e) => {
                            e.preventDefault()
                            setModalPage(
                                `${routes.geneticAnalyses.add.url}?modal=true`,
                            )
                        }}>
                        Register Analysis
                    </Button>
                </div>
            </div>
            <div className="row">
                <Kanban
                    reporters={reporters ?? []}
                    suppliers={suppliers ?? []}
                    getAnalyses={(status) => getDataByStatus(state, status)}
                    loadAll={loadAll}
                    filter={currentFilter}
                    openModal={setModalPage}
                />
            </div>
            {modalPage ? (
                <ModalDialog
                    actions={[
                        {
                            text: "Close",
                            onClick: closeModalPage,
                        },
                    ]}
                    width="800px"
                    height="100%"
                    shouldCloseOnOverlayClick={false}
                    scrollBehavior="outside">
                    <iframe
                        ref={(e) => {
                            if (e?.contentWindow) {
                                e.contentWindow.closeModal = closeModalPage
                            }
                        }}
                        className="kanban-modal"
                        src={modalPage}
                        style={{ width: "100%", height: "75vh" }}
                        frameBorder="0"></iframe>
                </ModalDialog>
            ) : null}
            <SelectionModal
                selection={selection}
                onClose={closeSelection}
                onAction={closeSelectionPage}
                key={uniqueId("key-")}
                api={api}
            />
        </div>
    )
}

export default withStateContext(SupervisorDashboard, initialState, reducer)
