import React from "react"
import { Link } from "react-router-dom"
import { routes } from "../../shared/routing"

function openRegistrationModal(item, openPage) {
    const url = routes.geneticAnalyses.register.buildUrl(
        item.barcode,
        item.analysisType,
    )
    openPage(url)
}

function RegisterAction({ item, openPage }) {
    return (
        <div className="register-action">
            <Link
                className="ml-2"
                onClick={(e) => {
                    e.preventDefault()
                    openRegistrationModal(item, openPage)
                }}
                to="">
                +
            </Link>
        </div>
    )
}

function SelectAction({ item, status, toggleStatus = () => {} }) {
    return (
        <div
            className={`select-action select-action-${
                status ? "selected" : "unselected"
            }`}
            onClick={() => toggleStatus(item.barcode, !status)}></div>
    )
}

export const cardActions = {
    NotYetRegistered: (data) => (
        <RegisterAction item={data.item} openPage={data.onOpen} />
    ),
    Registered: (data) => (
        <SelectAction
            item={data.item}
            status={data.selected}
            toggleStatus={data.onSelect}
        />
    ),
    Shipped: () => null,
    Analysed: (data) => (
        <SelectAction
            item={data.item}
            status={data.selected}
            toggleStatus={data.onSelect}
        />
    ),
    Assigned: (data) => (
        <SelectAction
            item={data.item}
            status={data.selected}
            toggleStatus={data.onSelect}
        />
    ),
    Done: () => null,
}
