import React from "react"

export default function Card({ type, style, title, language, actions, body }) {
    return (
        <div className={`kanban-card kanban-card-${type} kanban-card-${style}`}>
            <div className="card-header">
                <div className="card-title">
                    {title} {language}
                </div>
                <div className="card-actions">{actions}</div>
            </div>
            <div className="card-data">{body}</div>
        </div>
    )
}
