import { today, toDays, toIta } from "../../shared/dates"

export function getDueStatus(dueDate) {
    if (!dueDate) {
        return ""
    }

    const due = toIta(dueDate)
    const diff = toDays(today, due)
    if (diff < 0) return "overdue"
    if (diff <= 2) return "coupleofdays"
    if (diff <= 5) return "week"
    return "morethanaweek"
}

export function getDoneStatus(doneDate, dueDate) {
    const due = toIta(dueDate)
    const done = toIta(doneDate)
    const diff = toDays(done, due)

    if (diff < 0) return "overdue"
    return "ontime"
}

export const cardStyles = {
    NotYetRegistered: () => "",
    Registered: (item) => getDueStatus(item.dueDate),
    Shipped: (item) => getDueStatus(item.dueDate),
    Analysed: (item) => getDueStatus(item.dueDate),
    Assigned: (item) => getDueStatus(item.dueDate),
    Done: (item) => getDoneStatus(item.doneDate, item.dueDate),
}
