import React, { useState } from "react"
import { SelectField } from "../shared/components/selectfield"
import Button from "@atlaskit/button"

export default function SelectOptionAction({
    action,
    onAction,
    isEnabled = true,
    onSelectionChange = () => {},
}) {
    const [selectedOption, setSelectedOption] = useState(null)
    return (
        <>
            <div className="action-options">
                <SelectField
                    options={action.options}
                    value={selectedOption}
                    onChange={(e) => {
                        const value = e.target.value
                        setSelectedOption(value)
                        onSelectionChange(value)
                    }}
                />
            </div>
            <div className="action-button">
                <Button
                    appearance="primary"
                    isDisabled={!selectedOption || !isEnabled}
                    onClick={() => onAction(selectedOption)}>
                    {action.name}
                </Button>
            </div>
        </>
    )
}
